<script>
import AdComponent from '../../shared-component/ad-component/ad-component.vue'
import {HandleFavoriteMixin} from '../../../mixins/handle-favorite-mixin.js'

import AnnoncesService, { SearchOptions } from '../../../services/AnnoncesService.js'
import Const from "../../../model/Const.js"

import AdGrid from '../../shared-component/ad-grid/ad-grid.vue'

export default {
    name: "CategoryPage",
    components: {
        AdComponent,
        AdGrid,
    },
    mixins: [HandleFavoriteMixin],
    props: ['searchOptions','templateMode'],

    data() {
        return {
            category : "",
            description:"",
            // Collapse filter boolean to handle collapse filter margin if filter is hidden
            visible: false,
            // Listed ads in category page (should come from DB)
            itemsList : [],
            // Copy of itemsList based on user filters
            sortedItemsList: [],
            mySearchOptions: new SearchOptions().loadFromQuery(this.$route.query),
            rechercheEnCours:true,
            sortOptions:[
                { value: null, text: 'Trier par' ,disabled: true},
                { value: '1', text: 'Plus récentes' },
                { value: '2', text: 'Plus anciennes' },
                { value: '3', text: 'Prix croissants' },
                { value: '4', text: 'Prix décroissants'}
            ],
            sortSelected: '1',
        }
    },

    watch: {
      'sortSelected': function(newVal, oldVal) {
          console.info("sortSelected : " + newVal + " , " + oldVal);
        this.itemsList = [];
         this.mySearchOptions.sort = newVal;
         this.rechercheEnCours=true;
        AnnoncesService.getAllAnnonces(this.itemsList,true,this.mySearchOptions).then(()=>{
            this.rechercheEnCours=false
            this.$refs.adGrid.setAnnonces(this.itemsList);
        });
      }
    },
    methods: {
        // Sort itemList by ascending price, auto-updated by computed property
        sortByPriceAscending() {
            this.itemsList.sort((a, b) => a.price - b.price)
        },
        // Sort itemList by descending price, auto-updated by computed property
        sortByPriceDescending() {
            this.itemsList.sort((a, b) => b.price - a.price)
        },
        getDepartementByCode(code){
            return Const.getDepartementByCode(code);
        },
        getRegionByCode(code){
            return Const.getRegionByCode(code);
        },
        generateImageURL(annonce){
            if (annonce.photo1){
                let downloadURL = annonce.generateImageURL()
                console.info("downloadURL = " + downloadURL);
               return downloadURL;
            }

            return undefined;
        },    
        getBackgroundImage(){
            
            return Const.getCategoryByValue(this.mySearchOptions.cat).image;
        },

        showNextEntries(){
            if (!this.rechercheEnCours){
                this.rechercheEnCours = true;
                 AnnoncesService.getAllAnnonces(this.itemsList,false,this.mySearchOptions).then(()=>{
                this.rechercheEnCours=false
                this.$refs.adGrid.setAnnonces(this.itemsList);
                });
            } else {
                console.info("une recherche est deja en cours on ne fait rien .")
            }
           
        },

        hasMoreAnnonces(){
            return AnnoncesService.hasMoreAnnonces();
        },

        getBanniereStyle(){
            if (this.templateMode==1){
                return "position:absolute;left:0px;width:100vw;height:70vh;";
            } else {
                return "position:relative;height:276px";
            }
        },
        getBanniereLogo(){
            return this.computedBackground
        }
       
    },
    created(){
        console.info("category-page created");
         this.$emit('headerState', 2);
    },
    mounted() {
        if (this.templateMode > 1){
            // document.body.style.paddingBottom = '10px';
        }
       
        window.onscroll = () => {
            //let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight >= document.documentElement.offsetHeight;
            //console.info("document.documentElement.scrollTop = " + document.documentElement.scrollTop);
            //console.info("window.innerHeight = " + window.innerHeight);
            //console.info("document.documentElement.offsetHeight = " + document.documentElement.offsetHeight);
            if ((window.innerHeight + window.pageYOffset) >= document.body.offsetHeight) {
                console.info("Bottom of Window ! Getting more Data...");
                if (this.hasMoreAnnonces()){
                     console.info("new Entries Added !");
                    this.showNextEntries();
                } else {
                    console.info("No More Entry !");
                }
            }
        };

        this.itemsList = [];
        

        if (this.mySearchOptions.cat){
            this.category = Const.getCategoryByValue(this.mySearchOptions.cat).text;
            // eslint-disable-next-line no-empty
            switch(this.mySearchOptions.cat){
                case 1:
                    this.description = "Découvrez la meilleure poussette pour votre enfant."
                    break;
                case 2: 
                    this.description = "Trouvez le moyen le plus sur d'amener votre enfant à travers le monde."
                    break;
                case 3 : 
                    this.description = "Préparez une jolie chambre à votre enfant."
                    break;
                case 4 :
                    this.description = "Pour que bébé puisse marcher courir et s'épanouir."
                    break;
                case 5:
                    this.description = "\"L'art est un jeu d'enfant. \" - Max Ernst "
                    break;
                case 6 : 
                    this.description = "Que votre enfant soit, chevalier, princesse, dragon, ou pompier, trouvez l'histoire qui le fera rêver."
                    break;
                case 7 : 
                    this.description = "C'est l'heure du bain ? Trouvez ce dont vous avez besoin pour un bébé tout propre."
                    break;
                case 8 : 
                    this.description = "Bébé a faim ! Trouvez ce qu'il lui faut pour apprécier ses repas."
                    break;
                case 9 : 
                    this.description = "Equipez-vous pour vos sorties avec bébé."
                    break;

            }
        } else {
            this.category = "Toute catégorie";
            this.description = "Découvrez les meilleurs équipements pour bébé que vous recherchez."
                 
        }
        
       
        AnnoncesService.getAllAnnonces(this.itemsList,true,this.mySearchOptions).then(()=>{
            this.rechercheEnCours=false
            this.$refs.adGrid.setAnnonces(this.itemsList);
        });

    },
    computed: {
     
          // Background image required for category page (computed property needed)
        computedBackground() {
            if (this.mySearchOptions.cat){
                return require(`../../../assets/logosCat/${this.getBackgroundImage()}`)
            } else {
                return require(`../../../assets/logosCat/toutecathegorie.png`)
            }
            
        },
        // Pagination methods
        lists () {
            return this.itemsList;
        },
        rows() {
            return this.itemsList.length
        },
    }

};
</script>

<style lang="scss">
    @import "category-page.scss";
</style>
<template src="./category-page.html"></template>